<template>
  <el-card>
    <div slot="header">
      <span>Tenants</span>
    </div>
    <el-form :inline="true">
      <el-form-item label="Servicio">
        <select-service v-model="serviceId"></select-service>
      </el-form-item>
      <el-form-item>
        <el-button size="mini" type="primary" @click="search">
          Buscar
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      v-loading="loading"
      style="width: 100%"
      :data="tenants"
      stripe
      border
    >
      <el-table-column prop="serviceName" label="Servicio"></el-table-column>
      <el-table-column prop="id" label="Tenant"></el-table-column>
      <el-table-column prop="tenantQuantity" label="Cantidad"></el-table-column>
      <el-table-column prop="serverEndpoint" label="Servidor"></el-table-column>
    </el-table>
  </el-card>
</template>
<script>
export default {
  data() {
    return {
      tenants: [],
      loading: false,
      serviceId: 1,
    }
  },
  mounted() {
    this.search()
  },
  methods: {
    search() {
      const _this = this
      _this.tenants = []
      if (_this.serviceId) {
        _this.loading = true
        _this.$http.get(`tenants/${_this.serviceId}/findByService`).then(
          response => {
            _this.tenants = response.body
            _this.loading = false
          },
          response => {
            _this.loading = false
          },
        )
      } else {
        _this.$message.error('Oops, debe seleccionar un servicio.')
      }
    },
  },
}
</script>
